import React from "react";
import {Link} from "react-router-dom"; // eslint-disable-line
import DeviceIdentifier from "react-device-identifier";

// import Welcome from "../Welcome";
import Slot from "../Slot";
import Wideslot from "../Wideslot"
import Game from "../Game";

import StackD from "../../assets/stackd.png"
import Stack1 from "../../assets/stack1.svg";
import Stack2 from "../../assets/stack2.svg";
import Muscle from "../../assets/muscle.png";

import "./style.css"

export default function Main () {
    return(
        <main className="main">
        <div className="sticky">
        <b>UPDATE 05/21</b><br />
        Currently hired by Rihanna and not available for booking until further notice.<br />
        More details can still be found in the section 'about'.
        </div>
        <div className="slots-container">
        <div className="wideslot-container">
                <Wideslot title="Hello. I'm Gosha - and I do DevOps (mostly)." 
                pic={StackD}
                />
            </div>
            <div className="slot-container">
                <Slot title="coding." text1="I’m also a Full Stack Web Developer - and I have papers for that." pic={Stack1} />
                <Slot title="other stuff." text1="I have done some electronics along with some ERP stuff in the past." pic={Stack2} />
                <Slot title="attitude." text1="I love working overtime, learning new things and I offer some kickass Polish labor!" pic={Muscle} />
            </div>
        </div>
           <DeviceIdentifier isDesktop={true}>
                <Game />
            </DeviceIdentifier>
        </main>
    );
}