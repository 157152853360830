import React from "react";

import "./style.css";

export default function index({title, pic}) {
    return(
        <div className="wideslot">
            <h3>{title}</h3>
            <p className="upperP">I am a DevOps Engineer and I do DevOps stuff...</p>
            <p className="lowerP">...pipelines, K8s, Terraform, cloud functions, latte macchiato, fancy talk - you name it, I do it. Or at least try to.</p>
            <div className="picsBlock">
                <img className="wideslot-img" src={pic} alt="stack_icons" />
            </div>
        </div>
    );
}

